import React from "react"
import { PageProps } from "gatsby"
import Layout from "../components/layout"
import SEO from "../components/seo"
import Connect from "../components/pages/zone/connect"

/**
 * NOTE: This 404 page is currently not accessible in production as Amplify has a reroute rule to index.
 */

const NotFoundPage: React.FC<PageProps> = pageProps => {
  return (
    <Layout pageProps={pageProps}>
      <SEO title="Habitats | 404" />
      <Connect 
        headerText="Page Not Found"
        bodyText="We couldn't find that page."
      />
    </Layout>
  )
}

export default NotFoundPage
